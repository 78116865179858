import React, { useState, useEffect, useRef } from "react";
import "./tutorial.css";

const Tutorial = () => {
    const tooltipRef = useRef(null);

    useEffect(() => {
        let timeout;

        const tooltipElement = tooltipRef.current;

        tooltipElement.style.opacity = 0;

        timeout = setTimeout(() => {
            tooltipElement.style.opacity = 1;
        }, 3000);

        const handleMouseMove = (event) => {
            let cursorX = event.clientX + 50;
            let cursorY = event.clientY + 50;

            if (tooltipElement.style.opacity == 1) {
                tooltipElement.style.top = `${cursorY}px`;
                tooltipElement.style.left = `${cursorX}px`;

                timeout = setTimeout(() => {
                    tooltipElement.style.opacity = 0;
                }, 3000);
            }
        };

        window.addEventListener("mousemove", handleMouseMove);

        return () => {
            clearTimeout(timeout);
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    return (
        <div>
            <div
                ref={tooltipRef}
                id="tut-tooltip"
                style={{
                    position: "absolute",

                    transform: "translate(-50%, -50%)",

                    borderRadius: "5px",
                    pointerEvents: "none",
                }}
            >
                <p>(Click and drag to view)</p>
            </div>
        </div>
    );
};

export default Tutorial;
