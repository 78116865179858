import React, { useEffect, useRef, useState } from "react";
import "./navbar.css";

const Navbar = ({ currentTag, onTagChange }) => {
    const openMenuRef = useRef(null);
    const closeMenuRef = useRef(null);
    const mobileMenuRef = useRef(null);
    const menuLinksRef = useRef(null);

    useEffect(() => {
        const openMenu = openMenuRef.current;
        const closeMenu = closeMenuRef.current;
        const mobileMenu = mobileMenuRef.current;
        const menuLinks = menuLinksRef.current;

        const toggleMenu = () => {
            mobileMenu.classList.toggle("open");
        };

        const closeMenuFunc = () => {
            mobileMenu.classList.remove("open");
        };

        openMenu.addEventListener("click", toggleMenu);
        closeMenu.addEventListener("click", toggleMenu);

        Array.from(menuLinks.children).forEach((link) => {
            link.addEventListener("click", closeMenuFunc);
        });

        // Cleanup function
        return () => {
            openMenu.removeEventListener("click", toggleMenu);
            closeMenu.removeEventListener("click", toggleMenu);
            Array.from(menuLinks.children).forEach((link) => {
                link.removeEventListener("click", closeMenuFunc);
            });
        };
    }, []);

    const handleTagChange = (newTag) => {
        onTagChange(newTag);
    };

    return (
        <>
            <div className="navBar">
                <div className="navMenu">
                    <a className="link" href="/">
                        <p>www.rubyred.world</p>
                    </a>
                    <div className="navContents">
                        <div className="navItem">
                            <div
                                onClick={() => handleTagChange("music")}
                                className="link internal"
                            >
                                Music
                            </div>
                        </div>
                        <div className="navItem">
                            <div
                                onClick={() => handleTagChange("video")}
                                className="link internal"
                            >
                                Videos
                            </div>
                        </div>
                        <div className="navItem">
                            <div
                                onClick={() => handleTagChange("shop")}
                                className="link"
                            >
                                Shop
                            </div>
                        </div>

                        <div className="socialLinks">
                            <a href="https://www.instagram.com/rubyred">
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/insta.svg`}
                                />
                            </a>

                            <a href="https://twitter.com/RubyRedOnline">
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/twitter.svg`}
                                />
                            </a>

                            <a href="https://www.tiktok.com/@rubyred.online">
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/tiktok.svg`}
                                    alt="TikTok"
                                />
                            </a>
                        </div>
                    </div>
                    <div
                        className="sandwichIcon"
                        id="menuControlOpen"
                        ref={openMenuRef}
                    >
                        =
                    </div>
                </div>
            </div>

            {/* Mobile menu */}
            <div className="mobile-menu" id="mobile-menu" ref={mobileMenuRef}>
                <div className="exit" id="menuControlClose" ref={closeMenuRef}>
                    x
                </div>
                <div className="mobileNavLinks" ref={menuLinksRef}>
                    <div className="mobileNavItem">
                        <a href="/" className="link internal">
                            Home
                        </a>
                    </div>
                    <div className="mobileNavItem">
                        <div
                            onClick={() => handleTagChange("music")}
                            className="link internal"
                        >
                            Music
                        </div>
                    </div>
                    <div className="mobileNavItem">
                        <div
                            onClick={() => handleTagChange("video")}
                            className="link internal"
                        >
                            Videos
                        </div>
                    </div>
                    <div className="mobileNavItem">
                        <div
                            onClick={() => handleTagChange("shop")}
                            className="link"
                        >
                            Shop
                        </div>
                    </div>

                    <div className="socialLinks">
                        <a href="https://www.instagram.com/rubyred">
                            <img
                                src={`${process.env.PUBLIC_URL}/images/insta.svg`}
                            />
                        </a>

                        <a href="https://twitter.com/RubyRedOnline">
                            <img
                                src={`${process.env.PUBLIC_URL}/images/twitter.svg`}
                            />
                        </a>

                        <a href="https://www.tiktok.com/@rubyred.online">
                            <img
                                src={`${process.env.PUBLIC_URL}/images/tiktok.svg`}
                            />
                        </a>
                    </div>
                    <div className="copyright">
                        <p>© 2024 Ruby Red Partners, LLC</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Navbar;
