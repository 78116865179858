import React, { useEffect, useState, useRef } from "react";
import "./newsletter.css";

const Newsletter = () => {
    const [showNewsletter, setShowNewsletter] = useState(false);
    const closeBtnRef = useRef(null);

    const closeModal = () => {
        setShowNewsletter(false);
    };

    useEffect(() => {
        if (closeBtnRef.current) {
            closeBtnRef.current.addEventListener("click", closeModal);
            window.addEventListener("keydown", (event) => {
                if (event.key === "Escape") {
                    closeModal();
                }
            });
        }

        const hasShownNewsletter = localStorage.getItem("hasShownNewsletter");

        if (hasShownNewsletter) {
            setShowNewsletter(false);
        } else {
            const timeout = setTimeout(() => {
                setShowNewsletter(true);
                localStorage.setItem("hasShownNewsletter", true);
            }, 5000 * 7);
            return () => clearTimeout(timeout);
        }
    }, []);

    return (
        <div>
            {showNewsletter && (
                // Insert your newsletter modal JSX here
                <div className="newsletter-modal">
                    <div className="formContent">
                        <p>
                            Sign up for our mailing list and be the first to
                            Know
                        </p>

                        <form
                            name="newsletter"
                            method="post"
                            action="/success/"
                        >
                            <input
                                type="hidden"
                                name="form-name"
                                value="newsletter"
                            />

                            <p style={{ width: "100%" }}>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                />
                            </p>
                            <p style={{ width: "100%" }}>
                                <button type="submit">
                                    <p>Submit</p>
                                </button>
                            </p>
                        </form>
                    </div>
                    <p
                        ref={closeBtnRef}
                        onClick={closeModal}
                        style={{
                            opacity: "0.2",
                            textDecoration: "underline",
                            marginTop: "4vh",
                            cursor: "pointer",
                        }}
                    >
                        No thanks
                    </p>
                </div>
            )}
        </div>
    );
};

export default Newsletter;
