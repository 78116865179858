import React, { useEffect, useRef } from "react";

const VideoBackground = () => {
    const videoRef = useRef(null);

    const startVideoPlayback = () => {
        const constraints = {
            video: { facingMode: "environment" },
        };

        navigator.mediaDevices
            .getUserMedia(constraints)
            .then(function (stream) {
                const video = videoRef.current;
                video.srcObject = stream;
                video.addEventListener("loadedmetadata", () => {
                    video.play().catch((error) => {
                        video.style.display = "none";
                        console.error("Error attempting to play video:", error);
                    });
                });

                // Set a flag in local storage to store camera access approval
                localStorage.setItem("cameraAccess", "granted");
            })
            .catch(function (error) {
                console.error("Unable to access the camera:", error);
            });
    };

    const handleCameraDenied = () => {
        // Handle the scenario where camera access is denied
        console.log("Camera access denied");
    };

    const requestCameraAccess = () => {
        // Request camera access from the user
        const constraints = {
            video: { facingMode: "environment" },
        };

        navigator.mediaDevices
            .getUserMedia(constraints)
            .then(function (stream) {
                // User has granted access, proceed with video playback
                startVideoPlayback();
            })
            .catch(function (error) {
                // User has denied access, handle accordingly
                handleCameraDenied();
            });
    };

    useEffect(() => {
        // Check if the device is mobile
        const isMobile =
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            );

        if (isMobile && navigator.mediaDevices && videoRef.current) {
            // Check if the user has already approved or denied camera access
            const cameraAccess = localStorage.getItem("cameraAccess");

            if (cameraAccess === "granted") {
                // User has already granted access, proceed with video playback
                startVideoPlayback();
            } else if (cameraAccess === "denied") {
                // User has already denied access, handle accordingly
                handleCameraDenied();
            } else {
                // User has not made a decision yet, ask for camera access
                requestCameraAccess();
            }
        }
    }, []);

    return (
        <div>
            <video
                ref={videoRef}
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 0,
                    objectFit: "cover",
                }}
                id="video-background"
                autoPlay
                playsInline
                muted
                controls={false} // Don't show controls
            ></video>
        </div>
    );
};

export default VideoBackground;
