import React, { useState } from "react";
import PageLayout from "../components/pageLayout/PageLayout";
import Navbar from "../components/navbar/Navbar";
import Gallery from "../components/gallery/Gallery";
import Tutorial from "../components/tutorial/Tutorial";

const Home = () => {
    const [currentTag, setCurrentTag] = useState("all");
    console.log(currentTag);

    return (
        <PageLayout>
            <Tutorial />
            <Navbar currentTag={currentTag} onTagChange={setCurrentTag} />
            <Gallery currentTag={currentTag} />
        </PageLayout>
    );
};

export default Home;
