import React, { useEffect } from "react";
import "./permissions.css";

const Permissions = ({ permissionGranted, setPermissionGranted }) => {
    useEffect(() => {
        if (typeof DeviceOrientationEvent === "undefined") {
            // DeviceMotionEvent is not supported, grant permission
            console.log("not supported");
            setPermissionGranted(true);
            return;
        }

        if (typeof DeviceOrientationEvent.requestPermission === "function") {
            console.log("supported");

            DeviceMotionEvent.requestPermission()
                .then((permissionState) => {
                    if (permissionState === "granted") {
                        setPermissionGranted(true);
                    }
                })
                .catch(console.error);
        } else {
            setPermissionGranted(true);
        }

        return () => {};
    });

    function handlePermissionGranted() {
        DeviceOrientationEvent.requestPermission()
            .then((permissionState) => {
                if (permissionState === "granted") {
                    setPermissionGranted(true);
                    console.log(permissionGranted);
                    const modalElement = document.getElementById("modal");
                    modalElement.style.display = "none";
                    // window.location.reload();
                } else {
                    setPermissionGranted(false);
                    const modalElement = document.getElementById("modal");
                    modalElement.style.display = "none";
                }
            })
            .catch(console.error);
    }

    return (
        <>
            <div className="accelerometer-modal" id="modal">
                <div id="permissions-modal">
                    <div id="modal-content">
                        <p>
                            Please allow access to device motion and orientation
                        </p>
                        <p>
                            This web app requires access to device motion and
                            orientation to function properly.
                        </p>
                        <button
                            className="btn"
                            onClick={handlePermissionGranted}
                        >
                            <p>Grant Permission</p>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Permissions;
