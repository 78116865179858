import React, { useState } from "react";
import "./Gallery.css";
import ThreeDGallery from "../threeDgallery/ThreeDGallery.js";
import VideoBackground from "../videoBackground/VideoBackground.js";

const itemData = [
    {
        title: '6-POINT "SEXAGRAM" SURGICAL SOFT HELMET',
        image: `${process.env.PUBLIC_URL}/images/beanie.webp`,
        tag: "shop",
        description:
            "designed with Head in Mind 💆✅\nwill not protect you from traumatic brain injury 👷❌\nwill cover your bald spot 👨‍🦲✅",
        url: "https://ruby-red-online.myshopify.com/collections/god-owns-my-sexagrams/products/6-point-sexagram-skull-cap",
    },

    {
        title: "Saboteur",
        image: `${process.env.PUBLIC_URL}/images/bw.webp`,
        tag: "music",
        description:
            "1. No Feeling Is Forever 2. Eat Me (feat. Clayjay) 3. A Planet For Us 4. Overflow 5. Too Late (To Call) 6. Doing It Right\n\nReleased June 10, 2022 via Virgin",
        url: "https://rubyredsound.bandcamp.com/album/saboteur",
    },

    {
        title: "God Tee",
        image: `${process.env.PUBLIC_URL}/images/god-tee.webp`,
        tag: "shop",
        description:
            "Sign a 360 deal with the Major Label in the Sky 📀\nAdvance your Salvation 🕊️\nRecoup by Judgement Day 🤞",
        url: "https://ruby-red-online.myshopify.com/collections/god-owns-my-sexagrams/products/god-owns-my-masters",
    },
    {
        title: "Ruby Red Logo",
        image: `${process.env.PUBLIC_URL}/images/logo.webp`,
        tag: "",
        description: "Designed in collaboration with @nick.dap",
        url: "",
    },
    {
        title: "Mood Audit Ring",
        image: `${process.env.PUBLIC_URL}/images/ring.webp`,
        tag: "shop",
        description:
            "Adjustable metal alloy\nDiscover how it feels\nComes with carmine box with internal LED",
        url: "https://ruby-red-online.myshopify.com/collections/god-owns-my-sexagrams/products/mood-ring",
    },
    {
        title: "Umber Logo Hoodie [SOLD OUT]",
        image: `${process.env.PUBLIC_URL}/images/hoodie.webp`,
        tag: "shop",
        description:
            "50% cotton 50% polyester preshrunk fleece\nwoven label\njust in time for Chilean winter",
        url: "",
    },
    {
        title: "Smuggler's Tote",
        image: `${process.env.PUBLIC_URL}/images/tote.webp`,
        tag: "shop",
        description:
            'Binds when equipped\nDesigned to carry argircultural products across state borders\n100% heavyweight cotton canvas\nBig and practical (16-1/5" x 16.5"',
        url: "https://ruby-red-online.myshopify.com/collections/god-owns-my-sexagrams/products/ruby-tote-bag",
    },
    {
        title: '6-point "Sexagram" Shirt [SOLD OUT]',
        image: `${process.env.PUBLIC_URL}/images/shirt.webp`,
        tag: "shop",
        description:
            "Grey like sky\nHigh quality Neck\n100% cotton heavyweight garment dye\nSlightly oversized fit\nWoven label",
        url: "https://ruby-red-online.myshopify.com/collections/god-owns-my-sexagrams/products/light-blue-ruby-t-shirt",
    },
    {
        title: "Can We Talk?",
        image: `${process.env.PUBLIC_URL}/images/talk.webp`,
        tag: "video",
        description:
            "Lyrics:\n\nCan we talk? Somewhere alone\nA shot in the dark\nA kiss through the phone\nHow could you believe You were special to me It’s really bustin’ me up It just gets hard to breathe\nOh, I know you wanna bombs away When I’m never knowin’ what to say And I'm so lifted by your touch This fucking around\nIs fucking me up\nCan we talk?\nAnd I said ooo, my love There’s just something about ya I just can’t keep getting on Ooo damn without your support\nOh, I know you wanna bombs away When I’m never knowin’ what to say And I'm so lifted by your touch This fucking around\nIs fucking me up\nCan we talk?\nI need something to hold As my life flies by And discussion gets old\nAm I just a boulder?\nMaking way for a summer home Give it up so I can go write a song about My love\nMy shelter\n",
        url: "https://www.youtube.com/watch?v=tWOmsWFzbPE&ab_channel=RubyRed",
    },
    {
        title: "Martina Soleil",
        image: `${process.env.PUBLIC_URL}/images/martina.webp`,
        tag: "video",
        description:
            "Creative Direction: Fernando Fine\nEdited By: Vornstars\nLA Footage: Olivia McDowell, Ben Eubanks + Karl Perkins\nSF Footage: Kiko Valle + Cameron Gazaway\nLettering: Aliza Maynard",
        url: "https://www.youtube.com/watch?v=kn_Cmi44dCU&ab_channel=RubyRed",
    },
    {
        title: "Overflow",
        image: `${process.env.PUBLIC_URL}/images/overflow.webp`,
        tag: "video",
        description:
            "Starring: Daniel Laner, Fernando Fine, Ana Hernandez-Tijero, Emily Delnicki\n Directed by: Gabe Hostetler & Fernando Fine\n Creative Direction: Maya Laner, Daniel Laner and Fernando Fine\n Cinematographer: Zach Sulak\n Editor: Seth Damany\n Colorist: Seth Damany\n First AC: Amir Rose\n Gaffer: David Geller\n Key Grip: Ali Maqsood\n Grip: Max Garcia\n Grooming: Claire Brooke\n Additional Garments: Heaven I Stay\n Production Assistance: Sunya Ahmed, Natalie Launder, Tim Jordan, Jaxon Buzzell\n Production Company: Puzzle Studios\n BTS Photography: Dylan Mondschein & Jaxon Buzzell\nManagement: S+ MGMT & The Revels Group: Jordan Smith, Jamil Davis, Matt Bauerschmidt & Ben Schecter",
        url: "https://www.youtube.com/watch?v=q2jD23l11vM&ab_channel=RubyRed",
    },
    {
        title: "Too Late (To Call)",
        image: `${process.env.PUBLIC_URL}/images/toolate.webp`,
        tag: "video",
        description:
            "Starring: Daniel Laner & Fernando Fine\nDirected by: Fernando Fine & Bryan Lamb\nCreative Direction: Fernando Fine\nExecutive Produced By: Ben Schecter, Matt Bauerschmidt & Jordan SmithProduced by: wølveesONLY\nDirector of Photography: Justin Jones\nFirst AC: Joe Tomcufcik\nGaffer: Tal Kissos\nEditor: Seth Damany\nColor: Olga Kryukova\nGAN Footage: Forgie\nGrooming: Claire Brooke\nBTS Photography: Karl Perkins, Blair Brown & Sydney Cattouse\nManagement: S+ MGMT & The Revels Group: Jordan Smith, Jamil Davis, Matt Bauerschmidt & Ben Schecter",

        url: "https://www.youtube.com/watch?v=Ggai7loHz48&ab_channel=RubyRedVEVO",
    },
    {
        title: "Area of Affect [EP] (2020)",
        image: `${process.env.PUBLIC_URL}/images/aoa.webp`,
        tag: "music",
        description:
            "‘Affect’ refers to the experience of emotion, feeling, and mood, which is really what every song on the EP is about, in one way or another,” explain the duo. “We, as human beings, are all little areas of affect, feeling our way through life, feeling a certain type of way.'\n\n1. All Night Long 2. Ozone 3. Superbloom 4. Live From The Grave (Interlude) (feat. okay coleman!) 5. How It Should Feel 6. Desert Star\n\nReleased June 26, 2020",

        url: "https://rubyredsound.bandcamp.com/album/area-of-affect",
    },
    {
        title: "Inner Saboteur - Mirror Campaign",
        image: `${process.env.PUBLIC_URL}/images/mirror.webp`,
        tag: "",
        description:
            "As part of the Saboteur EP rollout, circus mirrors were put up around LA + Oakland. As people would walk by, they could catch a glimpse of their own worst enemy - their inner Sabotuer.",

        url: "",
    },
    {
        title: "Besties Only (w/ Muddy Monk)",
        image: `${process.env.PUBLIC_URL}/images/besties.webp`,
        tag: "music",
        description:
            "Written by: F. Fine, D. Laner, G. Dietrich, R. Aartsen\nProd by: Ruby Red, F. Dupont\nEngineered by: F. Dupont\nAdditional vocals by: J. Schwartz",
        url: "https://too.fm/mbpz84w",
    },
    {
        title: "Back 2 Life",
        image: `${process.env.PUBLIC_URL}/images/back2life.webp`,
        tag: "music",
        description:
            "Written by: F. Fine, D. Laner\nProd by: Ruby Red, F. Dupont\nEngineered by: F. Dupont\nAdditional vocals by: F. Flores",
        url: "https://stem.ffm.to/rubyredback2life",
    },
    {
        title: "Love Gun",
        image: `${process.env.PUBLIC_URL}/images/lovegun.webp`,
        tag: "music",
        description:
            "Written by: F. Fine, D. Laner\nProd by: Ruby Red, F. Dupont\nEngineered by: F. Dupont\nAdditional vocals by: N. Sellers",
        url: "https://symphony.to/ruby-red/love-gun",
    },
];

const Gallery = ({ currentTag }) => {
    const filteredItems =
        currentTag === "all"
            ? itemData
            : itemData.filter((item) => item.tag === currentTag);

    return (
        <>
            <VideoBackground />
            <div className="galleryContainer">
                <ThreeDGallery itemsData={filteredItems} />
            </div>
        </>
    );
};

export default Gallery;
