import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home.js";
import Contact from "./pages/Contact.js";
import Success from "./pages/Success.js";
import Ritual from "./pages/Ritual.js";
import Gofundme from "./pages/Gofundme.js";

const Pages = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Gofundme />} />
                <Route path="/home" element={<Home />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/success" element={<Success />} />
            </Routes>
        </Router>
    );
};

export default Pages;
