import React from "react";
import "./PageLayout.css";

const PageLayout = ({ children }) => {
    const style = {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        minHeight: "100svh",
        overscrollBehaviorY: "contain",
    };

    return (
        <div className="gradientMask" style={style}>
            {children}
        </div>
    );
};

export default PageLayout;
