import React, { useState } from "react";
import PageLayout from "../components/pageLayout/PageLayout";

const Success = () => {
    return (
        <PageLayout>
            <div
                style={{
                    width: "100%",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                }}
                className="successContainer"
            >
                <p>Successfully submitted</p>
                <a href="/">
                    <button style={{ width: "fit-content", padding: "0 8px" }}>
                        <p>Back to home</p>
                    </button>
                </a>
            </div>
        </PageLayout>
    );
};

export default Success;
