import React, {
    useRef,
    useEffect,
    useState,
    useCallback,
    createRef,
} from "react"; // Import useCallback
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import * as TWEEN from "tween.js"; // Import TWEEN library from the correct package
import Permissions from "../permissions/Permissions.js";
import "./ThreeDGallery.css";
import { DeviceOrientationControls } from "./controls/DeviceOrientationControls.js";

function getRandom(min, max, bound) {
    let randomInt = Math.random() * (max - min) + min;
    if (randomInt < bound && randomInt > 0) {
        randomInt = +bound;
    } else if (Math.abs(randomInt) < bound && randomInt < 0) {
        randomInt = -bound;
    }
    return randomInt;
}

const ThreeDGallery = ({ itemsData }) => {
    const mountRef = useRef(null);

    const controlsRef = useRef(null);

    const [permissionGranted, setPermissionGranted] = useState(false);

    const isMobile = /Mobi|Android/i.test(navigator.userAgent); // Check if the user is on a mobile device

    // Function that takes an image as input and returns a promise that resolves with its aspect ratio
    const getAspectRatio = (image) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                const aspectRatio = img.width / img.height;
                resolve(aspectRatio);
            };
            img.onerror = (error) => {
                reject(error);
            };
            img.src = image;
        });
    };

    useEffect(() => {
        // Scene, camera, and renderer setup
        const scene = new THREE.Scene();
        scene.background = null;

        // scene.add(new THREE.AxesHelper(20));

        const camera = new THREE.PerspectiveCamera(
            75,
            window.innerWidth / window.innerHeight,
            0.1,
            1000
        );

        // Set the camera position to look from the scene center
        const cameraDistance = 0.1;
        camera.position.set(0, 0, cameraDistance);

        const renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true,
        });

        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.outputColorSpace = THREE.SRGBColorSpace;

        renderer.setSize(window.innerWidth, window.innerHeight);

        mountRef.current.appendChild(renderer.domElement);

        renderer.xr.enabled = true;
        let controls;

        if (
            isMobile &&
            permissionGranted &&
            !/iPad|Macintosh/i.test(navigator.userAgent)
        ) {
            camera.position.set(0, 0, 0);
            // console.log("Permission granted in loop", permissionGranted);

            controlsRef.current = new DeviceOrientationControls(camera);

            controlsRef.current.connect();
        } else {
            // Desktop controls (using OrbitControls)
            controlsRef.current = new OrbitControls(
                camera,
                renderer.domElement
            );
            // controlsRef.current.connect();

            // if (isMobile) {
            //     controlsRef.current.rotateSpeed = -0.5;
            // }

            controlsRef.current.rotateSpeed = isMobile ? -0.5 : -0.75;

            controlsRef.current.enablePan = false;
            controlsRef.current.minPolarAngle = Math.PI / 2.5;
            controlsRef.current.maxPolarAngle = Math.PI / 1.5;
            controlsRef.current.enableDamping = true;
        }

        // Handle window resize
        window.addEventListener("resize", () => {
            renderer.setSize(window.innerWidth, window.innerHeight);
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
        });

        // Load textures and create planes for each image
        const loader = new THREE.TextureLoader();
        const objects = [];
        const maxIterations = 100; // Set a maximum iteration count

        async function createImagePlane(item) {
            const currentImg = item.image;
            const aspectRatio = await getAspectRatio(currentImg);
            // console.log(aspectRatio);

            const planeWidth = aspectRatio > 1 ? 1 : aspectRatio;
            const planeHeight = aspectRatio > 1 ? 1 / aspectRatio : 1;

            const texture = loader.load(currentImg, (tex) => {
                tex.encoding = THREE.sRGBEncoding;
            });

            texture.magFilter = THREE.LinearFilter;
            texture.minFilter = THREE.LinearMipmapLinearFilter;
            // texture.transparent = true; // Set transparency to true

            // Create a plane with the image texture
            const planeGeometry = new THREE.PlaneGeometry(
                planeWidth,
                planeHeight
            );
            const planeMaterial = new THREE.MeshBasicMaterial({
                map: texture,
                transparent: true,
            }); // Set transparency to true
            const plane = new THREE.Mesh(planeGeometry, planeMaterial);

            plane.userData = {
                image: item.image,
                title: item.title,
                description: item.description,
                url: item.url,
            };

            let position,
                iterations = 0;
            let isOverlapping = false; // Define isOverlapping here

            do {
                position = new THREE.Vector3(
                    getRandom(-7, 7, 1),
                    getRandom(-1, 1, 0.5),

                    getRandom(-7, 7, 2)
                );

                // Check if the new position doesn't overlap with existing objects
                isOverlapping = objects.some(
                    (obj) => obj.position.distanceTo(position) < 2
                ); // Minimum separation

                iterations++;

                if (iterations >= maxIterations) {
                    console.warn(
                        `Exceeded maximum iterations (${maxIterations}).`
                    );
                    break;
                }
            } while (isOverlapping);

            if (iterations < maxIterations) {
                plane.position.copy(position);
                plane.lookAt(camera.position.x, camera.position.y, 0); // Make the plane always face the camera

                scene.add(plane);
                objects.push(plane);
            }
        }

        itemsData.forEach((item, index) => {
            createImagePlane(item);
        });

        const raycaster = new THREE.Raycaster();
        const mouse = new THREE.Vector2();

        const onImageClick = (event) => {
            mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
            mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

            raycaster.setFromCamera(mouse, camera);

            if (event.type === "touchend" && event.changedTouches) {
                const touch = event.changedTouches[0];
                mouse.x = (touch.clientX / window.innerWidth) * 2 - 1;
                mouse.y = -(touch.clientY / window.innerHeight) * 2 + 1;
            } else {
                mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
                mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
            }

            const intersects = raycaster.intersectObjects(objects);

            if (intersects.length > 0) {
                // console.log("successfully logged intersect");
                const selectedPlane = intersects[0].object;

                const tooltipContainer =
                    document.getElementById("tooltip-container");

                tooltipContainer.style.opacity = 1;
                tooltipContainer.style.pointerEvents = "auto";
                tooltipContainer.style.zIndex = 111;

                const navBar = document.getElementsByClassName("navBar")[0];
                navBar.style.display = "none";

                document.getElementById("tooltip-title").innerText =
                    selectedPlane.userData.title;

                document.getElementById("tooltip").innerText =
                    selectedPlane.userData.description;

                document.getElementById("tooltip-img").src =
                    selectedPlane.userData.image;

                let currentLink = document.getElementById("link-container");

                if (selectedPlane.userData.url) {
                    currentLink.href = selectedPlane.userData.url;
                    currentLink.style.display = "Block";
                } else {
                    currentLink.style.display = "none";
                }

                const closeTooltip = () => {
                    navBar.style.display = "grid";
                    tooltipContainer.style.opacity = 0;
                    tooltipContainer.style.pointerEvents = "none";
                    tooltipContainer.style.zIndex = 0;

                    // Remove event listeners
                    tooltipContainer.removeEventListener("click", closeTooltip);
                };

                tooltipContainer.addEventListener("click", closeTooltip);
                window.addEventListener("keydown", (event) => {
                    if (event.key === "Escape") {
                        closeTooltip();
                    }
                });
                // console.log("Selected plane position:", selectedPlane.position);
                // console.log("Current camera position:", camera.position);

                // reposition camera on desktop
                if (!isMobile) {
                    const vectorArray = [
                        selectedPlane.position.x,
                        selectedPlane.position.y,
                        selectedPlane.position.z,
                    ];

                    const maxAbsValue = Math.max(...vectorArray.map(Math.abs));

                    if (maxAbsValue > 0.5) {
                        let normalizeRatio = 0.5 / maxAbsValue;

                        for (let i = 0; i < vectorArray.length; i++) {
                            let currentVal = vectorArray[i];
                            vectorArray[i] = currentVal * -1 * normalizeRatio;
                        }
                    } else {
                        for (let j = 0; j < vectorArray.length; j++) {
                            let currentVal = vectorArray[j];
                            vectorArray[j] = currentVal * -1;
                        }
                    }

                    const targetPosition = {
                        x: vectorArray[0],
                        y: vectorArray[1],
                        z: vectorArray[2],
                    };
                    const tween = new TWEEN.Tween(camera.position)
                        .to(targetPosition, 1000)
                        .easing(TWEEN.Easing.Quadratic.InOut)
                        .start();
                }
            }
        };

        document.addEventListener("mousedown", (event) => {
            onImageClick(event);
        });

        document.addEventListener("touchend", onImageClick);

        const animate = () => {
            requestAnimationFrame(animate);

            if (controlsRef.current) {
                controlsRef.current.update();
            }
            TWEEN.update(); // Add this line to update the TWEEN animations

            renderer.render(scene, camera);
        };
        animate();

        // Cleanup on unmount
        return () => {
            mountRef.current.removeChild(renderer.domElement);
        };
    }, [itemsData, isMobile, permissionGranted]);

    // useeffect ends

    return (
        <div>
            {isMobile ? (
                <Permissions
                    permissionGranted={permissionGranted}
                    setPermissionGranted={setPermissionGranted}
                />
            ) : null}

            <div id="tooltip-container">
                <div className="tooltip-exit">+</div>
                <div className="tooltipContent">
                    <div className="column-left">
                        <img id="tooltip-img" />
                    </div>
                    <div className="column-right">
                        <div className="tooltipText">
                            <p id="tooltip-title"></p>
                            <p id="tooltip"></p>
                            <p style={{ marginTop: "4vh" }}>
                                <a
                                    id="link-container"
                                    target="_blank"
                                    style={{ width: "fit-content" }}
                                >
                                    <button
                                        style={{
                                            width: "fit-content",
                                            padding: "4px 16px",
                                        }}
                                    >
                                        Learn more
                                    </button>
                                </a>
                            </p>
                            <p style={{ color: "#f1eca7", marginTop: "4vh" }}>
                                Tap anywhere to exit
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div ref={mountRef} />
        </div>
    );
};

export default ThreeDGallery;
