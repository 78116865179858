import React from "react";

import PageLayout from "../components/pageLayout/PageLayout";
import Navbar from "../components/navbar/Navbar";
import Gallery from "../components/gallery/Gallery";

const Contact = () => {
    return (
        <PageLayout>
            <Navbar />
            <Gallery />
        </PageLayout>
    );
};

export default Contact;
