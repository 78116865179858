import React from "react";
import "./gofundme-styles/0ec3d6e62b58491c.css";
import "./gofundme-styles/a12c3e2922e080c4.css";
import "./gofundme-styles/e210d6d6ef1946b8.css";
import "./gofundme-styles/9cca3e47b0ec741f.css";
import "./gofundme-styles/ffb514e42a9a2e40.css";
import "./gofundme-styles/3e42d1ecba845521.css";
import "./gofundme-styles/694fd7eea271274f.css";
import "./gofundme-styles/cm.css";
import PageLayout from "../components/pageLayout/PageLayout";
import Navbar from "../components/navbar/Navbar";
import Gallery from "../components/gallery/Gallery";

const Gofundme = () => {
    const currentDate = new Date().getUTCFullYear();

    const donateLink =
        "https://stem.ffm.to/rubyredstranger";
    const donationAmount = "$1,422";
    const donationNum = "14";

    const handleShare = () => {
        const link = "www.rubyred.world";
        navigator.clipboard
            .writeText(link)
            .then(() => {
                alert("Copied link to clipboard");
            })
            .catch((err) => {
                console.error("Failed to copy: ", err);
            });
        console.log("clicked");
    };

    return (
        <>
            <div id="__next" style={{ fontFamily: "Rubik !important" }}>
                <div className="page-campaign-layout_campaignPageLayout__ZK9_s">
                    <div id="portal"></div>
                    <div className="t-campaign-page-template">
                        <div
                            className="t-campaign-page-template-top"
                            data-view-id="pg_donate_index"
                            data-tracking-namespace="header"
                        >
                            <header className="t-campaign-page-header hrt-header hrt-header--default">
                                <div className="hrt-width-full hrt-container">
                                    <nav className="hrt-header-nav">
                                        <div className="hrt-header-left">
                                            {/* <a
                                                className="hrt-tertiary-button hrt-base-button"
                                                href="https://www.instagram.com/rubyred/"
                                                target="_blank"
                                                aria-label="Contact"
                                            >
                                                Contact
                                            </a> */}
                                            <a
                                                className="hrt-tertiary-button hrt-base-button"
                                                href="https://open.spotify.com/artist/2f0NSj1t2L6JowHINXCFb6?si=2OZklOkmR-Sw_VTyFUZnnw"
                                                target="_blank"
                                                aria-label="Contact"
                                                style={{
                                                    transform: "scaleY(0.8)",
                                                }}
                                            >
                                                Music
                                            </a>
                                            <a
                                                className="hrt-tertiary-button hrt-base-button"
                                                href="https://www.youtube.com/channel/UC3pxi-EyeW5_9085jeKcvGg"
                                                target="_blank"
                                                aria-label="Contact"
                                                style={{
                                                    transform: "scaleY(0.8)",
                                                }}
                                            >
                                                Videos
                                            </a>
                                        </div>
                                        <div className="hrt-header-center">
                                            <a
                                                className="hrt-link hrt-link--gray-dark"
                                                aria-label="rubyred.world"
                                                href="/home"
                                            >
                                                <img
                                                    style={{
                                                        width: "96px",
                                                        height: "48px",
                                                    }}
                                                    src={`${process.env.PUBLIC_URL}/images/gofundme_bootleg.png`}
                                                />
                                            </a>
                                        </div>
                                        <div className="hrt-show-for-lg hrt-header-right">
                                            <div className="hrt-show-for-lg hrt-header-dropdown">
                                                <a
                                                    className="hrt-ml-1 hrt-tertiary-button hrt-base-button"
                                                    href="/home"
                                                    style={{
                                                        textDecoration: "none",
                                                        transform:
                                                            "scaleY(0.8)",
                                                    }}
                                                >
                                                    About
                                                </a>
                                            </div>
                                            <a
                                                className="hrt-ml-1 hrt-tertiary-button hrt-base-button"
                                                data-element-id="btn_nav_sign_in"
                                                href="https://ruby-red-online.myshopify.com/"
                                                style={{
                                                    transform: "scaleY(0.8)",
                                                }}
                                            >
                                                Shop
                                            </a>
                                        </div>
                                        <div className="hrt-hide-for-lg hrt-header-right"></div>
                                    </nav>
                                </div>
                            </header>
                        </div>
                        <div
                            className="t-campaign-page-template-alert"
                            data-view-id="pg_donate_index"
                        >
                            <div id="alert-portal"></div>
                        </div>
                        <div id="sticky-portal"></div>
                        <main className="campaign-page_campaign__SFW7x t-campaign-page-template-content hrt-global-wrapper">
                            <div
                                className="p-campaign"
                                data-view-id="pg_donate_index"
                            >
                                <div className="p-campaign-sidebar hide-for-mobile">
                                    <aside className="campaign-sidebar_sidebar__2TEAT o-campaign-sidebar">
                                        <div className="campaign-sidebar_wrapper__Ay4Wk">
                                            <div className="progress-meter_progressMeter__BYOlt">
                                                <div className="progress-meter_progressBar__JQJ3f">
                                                    <progress
                                                        className="a-progress-bar a-progress-bar--green"
                                                        max="100"
                                                        value="8.6"
                                                    ></progress>
                                                </div>
                                                <div className="progress-meter_progressMeterHeading__A6Slt">
                                                    <div
                                                        className="hrt-disp-inline"
                                                        style={{
                                                            transform:
                                                                "scaleY(0.8)",
                                                        }}
                                                    >
                                                        {donationAmount}&nbsp;
                                                    </div>{" "}
                                                    <span
                                                        className="hrt-text-body-sm hrt-text-gray"
                                                        style={{
                                                            transform:
                                                                "scaleY(0.8)",
                                                        }}
                                                    >
                                                        {" "}
                                                        raised of $100,000
                                                        goal&nbsp;
                                                    </span>
                                                    <span
                                                        className="hrt-text-gray-40"
                                                        style={{
                                                            transform:
                                                                "scaleY(0.8)",
                                                        }}
                                                    >
                                                        •
                                                    </span>{" "}
                                                    <span
                                                        className="hrt-text-gray-dark"
                                                        style={{
                                                            transform:
                                                                "scaleY(0.8)",
                                                        }}
                                                    >
                                                        {donationNum}&nbsp;
                                                    </span>
                                                    <span
                                                        className="hrt-text-body-sm hrt-text-gray"
                                                        style={{
                                                            transform:
                                                                "scaleY(0.8)",
                                                        }}
                                                    >
                                                        donations
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="campaign-page_shareAndDonate__qRlrx hrt-mb-2">
                                                <div
                                                    className="hrt-mb-1 hrt-mx-auto"
                                                    data-element-id="btn_share"
                                                >
                                                    <button
                                                        className="gfm-share-button hrt-primary-button hrt-primary-button--full hrt-primary-button--large hrt-primary-button--default hrt-base-button"
                                                        type="button"
                                                        onClick={handleShare}
                                                        style={{
                                                            transform:
                                                                "scaleY(0.8)",
                                                        }}
                                                    >
                                                        Share
                                                    </button>
                                                </div>
                                                <div
                                                    data-element-id="btn_donate"
                                                    style={{
                                                        transform:
                                                            "scaleY(0.8)",
                                                    }}
                                                >
                                                    <a
                                                        className="gfm-donate-button hrt-primary-button hrt-primary-button--full hrt-primary-button--large hrt-primary-button--default hrt-base-button"
                                                        href={donateLink}
                                                    >
                                                        Donate now
                                                    </a>
                                                </div>
                                            </div>
                                            <ul
                                                className="hrt-list-unstyled o-donation-list"
                                                data-testid="donation-list"
                                            >
                                                <li className="donation-list_donationListItem__EoNQQ">
                                                    <div
                                                        className="m-donation hrt-avatar-lockup"
                                                        data-testid="donation"
                                                    >
                                                        <div
                                                            className="hrt-avatar-lockup-image"
                                                            data-testid="hrt-avatar-lockup-image"
                                                        >
                                                            <div className="hrt-donor-avatar hrt-avatar hrt-avatar--large">
                                                                <svg
                                                                    aria-hidden="true"
                                                                    className="hrt-icon hrt-icon--large"
                                                                    focusable="false"
                                                                    viewBox="0 0 24 24"
                                                                >
                                                                    <use href="/_next/static/images/core-5a1baed9b6ab366779092c6fff258e9a78c2fc2ea440beea797e941b301f9b1f.svg#donor"></use>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="hrt-avatar-lockup-content"
                                                            data-testid="hrt-avatar-lockup-content"
                                                        >
                                                            <div
                                                                style={{
                                                                    transform:
                                                                        "scaleY(0.8)",
                                                                }}
                                                            >
                                                                Jamil Davis
                                                            </div>
                                                            <ul className="donation_donationList__fa1FT hrt-list-unstyled hrt-meta-list hrt-meta-list--bullet">
                                                                <li className="hrt-meta-list-item">
                                                                    <span
                                                                        className="hrt-font-bold"
                                                                        style={{
                                                                            transform:
                                                                                "scaleY(0.8)",
                                                                        }}
                                                                    >
                                                                        $1
                                                                    </span>
                                                                </li>
                                                                <li
                                                                    className="hrt-meta-list-item"
                                                                    style={{
                                                                        transform:
                                                                            "scaleY(0.8)",
                                                                    }}
                                                                >
                                                                    <span>
                                                                        1d
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="donation-list_donationListItem__EoNQQ">
                                                    <div
                                                        className="m-donation hrt-avatar-lockup"
                                                        data-testid="donation"
                                                    >
                                                        <div
                                                            className="hrt-avatar-lockup-image"
                                                            data-testid="hrt-avatar-lockup-image"
                                                        >
                                                            <div className="hrt-donor-avatar hrt-avatar hrt-avatar--large">
                                                                <svg
                                                                    aria-hidden="true"
                                                                    className="hrt-icon hrt-icon--large"
                                                                    focusable="false"
                                                                    viewBox="0 0 24 24"
                                                                >
                                                                    <use href="/_next/static/images/core-5a1baed9b6ab366779092c6fff258e9a78c2fc2ea440beea797e941b301f9b1f.svg#donor"></use>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="hrt-avatar-lockup-content"
                                                            data-testid="hrt-avatar-lockup-content"
                                                        >
                                                            <div
                                                                style={{
                                                                    transform:
                                                                        "scaleY(0.8)",
                                                                }}
                                                            >
                                                                Anonymous
                                                            </div>
                                                            <ul className="donation_donationList__fa1FT hrt-list-unstyled hrt-meta-list hrt-meta-list--bullet">
                                                                <li className="hrt-meta-list-item">
                                                                    <span
                                                                        className="hrt-font-bold"
                                                                        style={{
                                                                            transform:
                                                                                "scaleY(0.8)",
                                                                        }}
                                                                    >
                                                                        $1,000
                                                                    </span>
                                                                </li>
                                                                <li
                                                                    className="hrt-meta-list-item"
                                                                    style={{
                                                                        transform:
                                                                            "scaleY(0.8)",
                                                                    }}
                                                                >
                                                                    <span>
                                                                        1d
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="donation-list_donationListItem__EoNQQ">
                                                    <div
                                                        className="m-donation hrt-avatar-lockup"
                                                        data-testid="donation"
                                                    >
                                                        <div
                                                            className="hrt-avatar-lockup-image"
                                                            data-testid="hrt-avatar-lockup-image"
                                                        >
                                                            <div className="hrt-donor-avatar hrt-avatar hrt-avatar--large">
                                                                <svg
                                                                    aria-hidden="true"
                                                                    className="hrt-icon hrt-icon--large"
                                                                    focusable="false"
                                                                    viewBox="0 0 24 24"
                                                                >
                                                                    <use href="/_next/static/images/core-5a1baed9b6ab366779092c6fff258e9a78c2fc2ea440beea797e941b301f9b1f.svg#donor"></use>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="hrt-avatar-lockup-content"
                                                            data-testid="hrt-avatar-lockup-content"
                                                        >
                                                            <div
                                                                style={{
                                                                    transform:
                                                                        "scaleY(0.8)",
                                                                }}
                                                            >
                                                                Daniel's father
                                                            </div>
                                                            <ul className="donation_donationList__fa1FT hrt-list-unstyled hrt-meta-list hrt-meta-list--bullet">
                                                                <li className="hrt-meta-list-item">
                                                                    <span
                                                                        className="hrt-font-bold"
                                                                        style={{
                                                                            color: "red",
                                                                            transform:
                                                                                "scaleY(0.8)",
                                                                        }}
                                                                    >
                                                                        -$100
                                                                    </span>
                                                                </li>
                                                                <li
                                                                    className="hrt-meta-list-item"
                                                                    style={{
                                                                        transform:
                                                                            "scaleY(0.8)",
                                                                    }}
                                                                >
                                                                    <span>
                                                                        1d
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="donation-list_donationListItem__EoNQQ">
                                                    <div
                                                        className="m-donation hrt-avatar-lockup"
                                                        data-testid="donation"
                                                    >
                                                        <div
                                                            className="hrt-avatar-lockup-image"
                                                            data-testid="hrt-avatar-lockup-image"
                                                        >
                                                            <div className="hrt-donor-avatar hrt-avatar hrt-avatar--large">
                                                                <svg
                                                                    aria-hidden="true"
                                                                    className="hrt-icon hrt-icon--large"
                                                                    focusable="false"
                                                                    viewBox="0 0 24 24"
                                                                >
                                                                    <use href="/_next/static/images/core-5a1baed9b6ab366779092c6fff258e9a78c2fc2ea440beea797e941b301f9b1f.svg#donor"></use>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="hrt-avatar-lockup-content"
                                                            data-testid="hrt-avatar-lockup-content"
                                                        >
                                                            <div
                                                                style={{
                                                                    transform:
                                                                        "scaleY(0.8)",
                                                                }}
                                                            >
                                                                Anonymous
                                                            </div>
                                                            <ul className="donation_donationList__fa1FT hrt-list-unstyled hrt-meta-list hrt-meta-list--bullet">
                                                                <li className="hrt-meta-list-item">
                                                                    <span
                                                                        className="hrt-font-bold"
                                                                        style={{
                                                                            transform:
                                                                                "scaleY(0.8)",
                                                                        }}
                                                                    >
                                                                        $50
                                                                    </span>
                                                                </li>
                                                                <li className="hrt-meta-list-item">
                                                                    <span
                                                                        style={{
                                                                            transform:
                                                                                "scaleY(0.8)",
                                                                        }}
                                                                    >
                                                                        1d
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="donation-list_donationListItem__EoNQQ">
                                                    <div
                                                        className="m-donation hrt-avatar-lockup"
                                                        data-testid="donation"
                                                    >
                                                        <div
                                                            className="hrt-avatar-lockup-image"
                                                            data-testid="hrt-avatar-lockup-image"
                                                        >
                                                            <div className="hrt-donor-avatar hrt-avatar hrt-avatar--large">
                                                                <svg
                                                                    aria-hidden="true"
                                                                    className="hrt-icon hrt-icon--large"
                                                                    focusable="false"
                                                                    viewBox="0 0 24 24"
                                                                >
                                                                    <use href="/_next/static/images/core-5a1baed9b6ab366779092c6fff258e9a78c2fc2ea440beea797e941b301f9b1f.svg#donor"></use>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="hrt-avatar-lockup-content"
                                                            data-testid="hrt-avatar-lockup-content"
                                                        >
                                                            <div
                                                                style={{
                                                                    transform:
                                                                        "scaleY(0.8)",
                                                                }}
                                                            >
                                                                Anonymous
                                                            </div>
                                                            <ul className="donation_donationList__fa1FT hrt-list-unstyled hrt-meta-list hrt-meta-list--bullet">
                                                                <li className="hrt-meta-list-item">
                                                                    <span
                                                                        className="hrt-font-bold"
                                                                        style={{
                                                                            transform:
                                                                                "scaleY(0.8)",
                                                                        }}
                                                                    >
                                                                        $24
                                                                    </span>
                                                                </li>
                                                                <li
                                                                    className="hrt-meta-list-item"
                                                                    style={{
                                                                        transform:
                                                                            "scaleY(0.8)",
                                                                    }}
                                                                >
                                                                    <span>
                                                                        1d
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </aside>
                                </div>
                                {/* <div
                                    className="p-campaign-collage hrt-bg-blue-10 hrt-text-blue-dark"
                                    style={{
                                        borderRadius: "16px",
                                        overflow: "hidden",
                                        width: "100%",
                                    }}
                                > */}
                                {/* <video
                                        src={`${process.env.PUBLIC_URL}/images/horizontal_extended_music.mp4`}
                                        height="auto"
                                        poster={`${process.env.PUBLIC_URL}/images/title_card.png`}
                                        controls
                                    >
                                        Your browser does not support the video
                                        tag
                                    </video> */}
                                <div
                                    style={{
                                        gridArea: "collage",
                                        paddingTop: "56.25%",
                                        position: "relative",
                                        marginTop: "1.5em",
                                    }}
                                >
                                    <iframe
                                        width="inherit"
                                        // height="100%"
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "0.75em",
                                        }}
                                        src="https://www.youtube-nocookie.com/embed/HH57mghLxy8?si=9pLWRQdk9u_vwVSs&amp;controls=1"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerpolicy="strict-origin-when-cross-origin"
                                        allowfullscreen
                                    ></iframe>
                                </div>
                                {/* </div> */}
                                <div>
                                    <h1
                                        style={{
                                            width: "fit-content",
                                            whiteSpace: "nowrap",
                                            marginBottom: "0",
                                        }}
                                    >
                                        OPERATION: MAX MARTIN
                                    </h1>
                                </div>
                                <div className="p-campaign-byline">
                                    <div className="m-campaign-byline">
                                        <div className="hrt-align-center hrt-disp-flex">
                                            <ul className="hrt-list-unstyled hrt-avatar-stack hrt-mr-2">
                                                <li className="hrt-avatar-stack-item hrt-avatar-stack-item--0">
                                                    <div className="hrt-default-avatar hrt-avatar hrt-avatar--large">
                                                        <img
                                                            style={{
                                                                borderRadius:
                                                                    "50%",
                                                                overflow:
                                                                    "hidden",
                                                            }}
                                                            src={`${process.env.PUBLIC_URL}/images/bw.webp`}
                                                        />
                                                    </div>
                                                </li>
                                            </ul>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "8px",
                                                }}
                                            >
                                                <div
                                                    className="m-campaign-byline-description"
                                                    style={{
                                                        transform:
                                                            "scaleY(0.8)",
                                                    }}
                                                >
                                                    Ruby Red is organizing this
                                                    fundraiser.
                                                </div>
                                                <div
                                                    className="progress-meter_progressMeter__BYOlt hrt-hide-for-lg"
                                                    style={{ margin: "0" }}
                                                >
                                                    <div className="progress-meter_progressBar__JQJ3f">
                                                        <progress
                                                            className="a-progress-bar a-progress-bar--green"
                                                            max="100"
                                                            value="8.6"
                                                        ></progress>
                                                    </div>
                                                    <div
                                                        className="progress-meter_progressMeterHeading__A6Slt"
                                                        style={{ margin: "0" }}
                                                    >
                                                        <div className="hrt-disp-inline">
                                                            {donationAmount}
                                                        </div>{" "}
                                                        <span className="hrt-text-body-sm hrt-text-gray">
                                                            {" "}
                                                            raised of $100,000
                                                            goal&nbsp;
                                                        </span>
                                                        <span className="hrt-text-gray-40">
                                                            •
                                                        </span>{" "}
                                                        <span className="hrt-text-gray-dark">
                                                            {donationNum}
                                                        </span>
                                                        <span className="hrt-text-body-sm hrt-text-gray">
                                                            donations
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="mobileButtons hrt-hide-for-lg"
                                        style={{ marginTop: "16px" }}
                                    >
                                        <div className="campaign-page_shareAndDonate__qRlrx hrt-mb-2">
                                            <div
                                                className="hrt-mb-1 hrt-mx-auto"
                                                data-element-id="btn_share"
                                            >
                                                <button
                                                    className="gfm-share-button hrt-primary-button hrt-primary-button--full hrt-primary-button--large hrt-primary-button--default hrt-base-button"
                                                    type="button"
                                                    onClick={handleShare}
                                                    style={{
                                                        transform:
                                                            "scaleY(0.8)",
                                                    }}
                                                >
                                                    Share
                                                </button>
                                            </div>
                                            <div
                                                data-element-id="btn_donate"
                                                style={{
                                                    transform: "scaleY(0.8)",
                                                }}
                                            >
                                                <a
                                                    className="gfm-donate-button hrt-primary-button hrt-primary-button--full hrt-primary-button--large hrt-primary-button--default hrt-base-button"
                                                    href={donateLink}
                                                >
                                                    Donate now
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-campaign-description">
                                    <div className="hrt-mb-0 hrt-mt-0 hrt-rule hrt-rule--horizontal"></div>
                                    <div className="hrt-py-2 hrt-disp-flex hrt-align-center">
                                        <span
                                            className="hrt-tag hrt-tag--green hrt-base-tag hrt-base-tag--default"
                                            style={{
                                                border: "1px solid #008748",
                                                cursor: "pointer",
                                                transform: "scaleY(0.8)",
                                            }}
                                        >
                                            Donation protected ⚔️
                                        </span>
                                    </div>
                                    <div className="hrt-mt-0 hrt-mb-0 hrt-rule hrt-rule--horizontal"></div>
                                    <div className="campaign-description_campaignDescription__6P_RU">
                                        <div
                                            className="campaign-description_content__C1C_5 hrt-mt-3 scale-children"
                                            style={{
                                                maxHeight: "fit-content",
                                            }}
                                        >
                                            <div>Hello,</div>
                                            <br />
                                            <div>We are Ruby Red.</div>
                                            <br />
                                            <div>
                                                The truth is, we need your help.
                                            </div>
                                            <br />
                                            <div>
                                                We’re tried of doing everything
                                                ourselves, and we figure it’s
                                                time we brought in some people
                                                that actually know what they’re
                                                doing.
                                            </div>
                                            <br />
                                            <div>
                                                That’s why we’re going to
                                                attempt to hire Max Martin to
                                                write a song for us.
                                            </div>
                                            <br />
                                            <div>
                                                Karl Martin Sandberg, known
                                                professionally as Max Martin, is
                                                a Swedish record producer and
                                                songwriter.
                                            </div>
                                            <br />
                                            <div>
                                                He’s been responsible for more
                                                #1 hits than every in the world.
                                            </div>
                                            <br />
                                            <div>(Minus the Beatles)</div>
                                            <br />
                                            <div>
                                                The thing is, Max Martin’s
                                                services aren’t exactly free,
                                                and at Ruby Red, we believe in
                                                paying creatives handsomely for
                                                their work.
                                            </div>
                                            <br />
                                            <div>
                                                According to an NPR article, Max
                                                Martin charges $100,000USD per
                                                song (not adjusted for
                                                inflation..)
                                            </div>
                                            <br />
                                            <div>
                                                We believe with your clear eyes,
                                                full hearts, and throbbing
                                                generosity, that no financial
                                                obstacle that we invent for
                                                ourselves shall prosper.
                                            </div>
                                            <br />
                                            <div>
                                                Pledging any amount of money,
                                                however big or small, is one
                                                step closer to fulfilling our
                                                teenage dream.
                                            </div>
                                            <br />
                                            <div>
                                                Together, we will make Max
                                                Martin an offer he will probably
                                                still refuse.
                                            </div>
                                            <br />
                                            <div className="hrt-mt-0 hrt-mb-0 hrt-rule hrt-rule--horizontal"></div>
                                            <br />

                                            <div>
                                                Want to support us a
                                                non-financial, low-commitment
                                                sorta way?&nbsp;
                                                <a
                                                    style={{
                                                        marginBottom: "48px",
                                                        transform:
                                                            "scaleY(0.8)",
                                                    }}
                                                    href="https://stem.ffm.to/rubyredstranger"
                                                >
                                                    Click here to preseave our
                                                    next single
                                                </a>
                                            </div>

                                            <br />
                                        </div>
                                    </div>
                                </div>
                                <div className="p-campaign-content">
                                    <div className="hrt-show-for-lg">
                                        <div className="hrt-disp-flex">
                                            <a
                                                className="hrt-secondary-button hrt-secondary-button--full hrt-secondary-button--large hrt-secondary-button--default hrt-base-button"
                                                data-element-id="btn_story_donate"
                                                href={donateLink}
                                                style={{
                                                    transform: "scaleY(0.8)",
                                                }}
                                            >
                                                Donate
                                            </a>
                                            <button
                                                className="hrt-ml-2 hrt-secondary-button hrt-secondary-button--full hrt-secondary-button--large hrt-secondary-button--default hrt-base-button"
                                                type="button"
                                                data-element-id="btn_story_share"
                                                onClick={handleShare}
                                                style={{
                                                    transform: "scaleY(0.8)",
                                                }}
                                            >
                                                Share
                                            </button>
                                        </div>
                                    </div>
                                    <div className="p-campaign-members">
                                        <div className="hrt-hide-for-lg hrt-rule hrt-rule--horizontal"></div>
                                        <div
                                            className="o-campaign-members"
                                            id="campaign-members"
                                        >
                                            <h2 className="hrt-disp-flex hrt-align-center campaign-members_campaignMembersHeader__S99vj">
                                                <div
                                                    className="campaign-members_campaignMembersHeaderTitle__2iegQ"
                                                    style={{
                                                        transform:
                                                            "scaleY(0.8)",
                                                    }}
                                                >
                                                    Organizer
                                                </div>
                                            </h2>
                                            <div className="hrt-mt-3 hrt-mb-5 campaign-members-main_campaignMembersMain__mmNDj">
                                                <div className="campaign-members-main_organizer__NRaa5">
                                                    <div className="hrt-avatar-lockup">
                                                        <div
                                                            className="hrt-avatar-lockup-image"
                                                            data-testid="hrt-avatar-lockup-image"
                                                        >
                                                            <div className="hrt-default-avatar hrt-avatar hrt-avatar--large">
                                                                <img
                                                                    style={{
                                                                        borderRadius:
                                                                            "50%",
                                                                        overflow:
                                                                            "hidden",
                                                                    }}
                                                                    src={`${process.env.PUBLIC_URL}/images/bw.webp`}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="hrt-avatar-lockup-content"
                                                            data-testid="hrt-avatar-lockup-content"
                                                        >
                                                            <div
                                                                className="hrt-font-bold"
                                                                style={{
                                                                    transform:
                                                                        "scaleY(0.8)",
                                                                }}
                                                            >
                                                                Ruby Red
                                                            </div>
                                                            <div
                                                                className="hrt-text-body-sm"
                                                                style={{
                                                                    transform:
                                                                        "scaleY(0.8)",
                                                                }}
                                                            >
                                                                Organizer
                                                            </div>
                                                            <div
                                                                className="hrt-text-body-sm"
                                                                style={{
                                                                    transform:
                                                                        "scaleY(0.8)",
                                                                }}
                                                            >
                                                                Los Angeles, CA
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="campaign-members-main_contact__xHCnO hrt-ml-7">
                                                    <a
                                                        href="https://www.instagram.com/rubyred/"
                                                        target="_blank"
                                                    >
                                                        <button
                                                            className="hrt-secondary-button hrt-secondary-button--inline hrt-secondary-button--medium hrt-secondary-button--default hrt-base-button"
                                                            type="button"
                                                            data-element-id="btn_organizer_contact"
                                                            style={{
                                                                transform:
                                                                    "scaleY(0.8)",
                                                            }}
                                                        >
                                                            Contact
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div></div>
                                    <div className="hrt-mt-0 hrt-mb-0 hrt-rule hrt-rule--horizontal"></div>
                                    <ul className="date-and-category_dateAndCategory__m56MT hrt-list-unstyled hrt-meta-list hrt-meta-list--bullet">
                                        <li className="hrt-meta-list-item">
                                            <span
                                                className="m-campaign-byline-created a-created-date"
                                                style={{
                                                    transform: "scaleY(0.8)",
                                                }}
                                            >
                                                {currentDate}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
                <button
                    className="hrt-disp-none hrt-base-button"
                    type="button"
                ></button>
            </div>
        </>
    );
};

export default Gofundme;
